<template>
  <div id="app">
    <coming-soon/>
  </div>
</template>

<script>
import ComingSoon from './components/pages/ComingSoon'

export default {
  name: 'App',
  components: {
    ComingSoon,
  }
}
</script>

<style>

</style>
