




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class Icons extends Vue {
  @Prop()
  icon: string;

  @Prop({default: 18})
  width: number;

  @Prop({default: 2})
  strokeWidth: number;

  @Prop({default: 18})
  height: number;

  @Prop({default: 'none'})
  fill: string;

  icons = {
    facebook: `<path xmlns="http://www.w3.org/2000/svg" d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/>`,
    instagram: `<rect x="2" y="2" width="20" height="20" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"/>`,
  };

}

export default Icons;
