

















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Icons from "../base/Icons.vue";

@Component({
  components: {
    Icons,
  }
})
class ComingSoon extends Vue {
  showPage = false;

  maxBgWidth = '100%';
  maxBgHeight = '100%';

  errors = {
    logo: false,
  };

  onImgError() {
    this.errors.logo = true;
  }

  created(): void {
    this.maxBgWidth = `${window.innerWidth}px`;
    this.maxBgHeight = `${window.innerHeight}px`;
  }

  mounted(): void {
    setTimeout(() => {
      this.showPage = true
    }, 40)
  }
}

export default ComingSoon;
